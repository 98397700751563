<template>
    <div class="main">
        <div class="container">
            <div class="flex cloud" id="cloud">
                <Menu></Menu>
                <div class="flex-item cloud-right">
                    <ul class="art-list" v-if="list.length">
                        <li  v-for="(item,index) in list" :key="index" class="art-list-item" @click="skipArticle(item.id)">
                            <div class="art-list-wrap">
                                <img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='item.cover2?item.cover2:item.cover1' class="bali-logo" v-if="isMobile">
                                <img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='item.cover1' class="bali-logo" v-else>
                                <div class="art-list-text" id="article">
                                    <div class="w100">
                                        <div class="sub-title">{{item.title}}</div>
                                        <p class="text-grey letter-break m-t-10">{{item.subhead}}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="tac" v-else>
                        <img src="/img/icon-empty.png" class="empty-pic"/>
                        <p>无内容</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data(){
            return{
                list:[]
            }
        },
        mounted(){
            this.queryData(this.$route.params.id)
        },
        beforeRouteUpdate(to,from,next){  //监听参数变化，实现查询不同数据
            this.queryData(to.params.id)
            next()
        },
        methods:{
            queryData(id){
                let _this = this
                this.$fetch.infoSearch({"currentPage": 0,"pageSize": 10, "typeId": id }).then(res=>{
                    if(res.data.code ==2000){
                       // console.log(res.data,res.data.data.rows)
                        _this.list = res.data.data.rows
                        console.log(_this.list)
                    }else{
                        this.$message.error('获取失败');
                    }
                }).catch(err=>{
                    this.$message.error('获取失败');
                    console.log(err)
                })
            },
            skipArticle(id){
                this.$router.push("/article/" + id)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .art-list{
        display: flex;
        flex-wrap:wrap;
        .art-list-item{
            width: 50%;
            .art-list-wrap{
                display: flex;
                min-height: 200px;
                background: #FFF;
                border: 1px solid #EEE;
                padding: 30px;
                box-sizing: border-box;
                white-space: normal;
                margin-bottom: 10px;
                margin-right: 10px;
                .bali-logo{
                    width: 90px;
                    height: 90px;
                }
                .art-list-text{
                    flex-grow: 1;
                    margin-left: 16px;
                    word-break: break-all;
                }
            }
        }
    }
    .mobile{
        .art-list{
            display: block;
            .art-list-item{
                width: auto;
                .art-list-wrap{
                    display: block;
                    padding: 0;
                    .bali-logo{
                        width: 100%;
                        height: auto;
                    }
                    .art-list-text{
                        margin-left: 0;
                        padding: 15px;
                    }
                }
            }
        }
    }
</style>
