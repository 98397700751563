<template>
    <div v-if="isShowFlag">
        <div class="main">
            <div class="container">
                <h2 class="title" v-show="isMobile">云产品</h2>
                <div class="flex cloud" id="cloud">
                    <Menu></Menu>
                    <div class="left-block m-r-15" v-if="false">
                        <div class="sub-bg tac cloud-title">精选分类</div>
                        <ul class="cloud-menu" @click="skipList">
                            <li v-for="(item,index) in classify" :key="index"  class="cloud-menu-item">
                                <div class="flex flex-middle">{{item.title}} <img alt="arrow" src="/img/arrow.png" class="m-l-5"></div>
                                <div class="text-grey cloud-menu-sub m-t-10">
                                    <span v-for="(col,num) in item.sub" :key="num">{{col}}<span class="split-bar">|</span></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="flex-item cloud-right">
                        <img alt="arrow" src="/img/cloud-banner1.png" class="w100" v-show="!isMobile">
                        <!-- 企业大学SaaS云-->
                        <div class="cloud-block m-t-10 pointer" @click="skipArticle(qiyedaxue.id)">
                            <div class="cloud-desc p15 tac" v-if="!isMobile">
                                <img alt="arrow" src="/img/cloud-banner2.png" v-default-img='qiyedaxue.cover1' class="product-cover">
                            </div>
                            <div class="cloud-desc">
                                <div class="p15 blue-title">{{qiyedaxue.title}}</div>
                                <div class="text-grey p-x-15">{{qiyedaxue.subhead}}</div>
                            </div>
                            <div class="cloud-desc p-x-15 tac" v-if="isMobile">
                                <img alt="arrow" src="/img/cloud-banner2.png" v-default-img='qiyedaxue.cover2?qiyedaxue.cover2:qiyedaxue.cover1' class="product-cover">
                            </div>
                        </div>
                        <!-- 心理服务SaaS云-->
                        <div class="cloud-block m-t-10 pointer" @click="skipArticle(xinlifuwu.id)">
                            <div class="cloud-desc">
                                <div class="p15 blue-title">{{xinlifuwu.title}}</div>
                                <div class="text-grey p-x-15">{{xinlifuwu.subhead}}</div>
                            </div>
                            <div class="cloud-desc tac p-x-15">
                                <img alt="arrow" src="/img/cloud-banner2.png" v-default-img='xinlifuwu.cover2?xinlifuwu.cover2:xinlifuwu.cover1' class="product-cover" v-if="isMobile">
                                <img alt="arrow" src="/img/cloud-banner2.png" v-default-img='xinlifuwu.cover1' class="product-cover" v-else>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 解决方案 -->
                <h2 class="m-t-30 title" id="solution">数字运营商</h2>
                <ul class="m-t-10 row list">
                    <li v-for="(item,index) in solution" :key="index" class="pointer" @click="skipArticle(item.id)">
                        <div class="list-item">
                            <div class="sub-title p15 blue-title">{{item.title}}</div>
                            <div class="p-x-15 text-grey flex" v-if="isMobile">
                                <img alt="arrow" src="/img/cloud-banner2.png" v-default-img='item.cover1' class="shrink solution-cover">
                                <div class="flex-item m-l-16"> {{item.subhead}}</div>
                            </div>
                            <div class="p-x-15 text-grey" v-else>{{item.subhead}}</div>
                            <!--<img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='item.cover2?item.cover2:item.cover1' class="mobile-cover" v-if="isMobile">-->
                        </div>
                    </li>
                </ul>
                <!-- 云教育 -->
              <h2 class="m-t-30 title" id="edution"> </h2>
              <ul class="edution">
                  <li class="m-t-10 edution-list" v-for="(item,index) in edution" :key="index">
                      <div class="shrink sub-bg edution-item edution-left">
                          <div class="sub-title">{{item.title}}</div>
                          <div class="m-t-10">{{item.desc}}</div>
                      </div>
                      <!-- item.sub  university-->
                        <div class="flex-item edution-sub">
                            <ul class="flex flex-wrap">
                                <!-- 八里云大学-->
                                <li class="edution-sub-item pointer" v-for="(col,num) in university" :key="'blydx'+num" v-show="index==0" @click="skipArticle(col.id)">
                                    <div class="bg-white edution-sub-inner" v-show="num<=3">
                                        <img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='col.cover2?col.cover2:col.cover1' class="mobile-cover" v-if="isMobile">
                                        <img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='col.cover1' class="bali-logo" v-else>
                                        <div class="m-l-16 flex-item art-list-text">
                                            <div class="sub-title">{{col.title}}</div>
                                            <div class="m-t-10 text-gray">{{col.subhead}}</div>
                                        </div>
                                    </div>
                                </li>
                                <!-- K12云学校 -->
                                <li class="edution-sub-item pointer" v-for="(col,num) in k12" :key="num" v-show="index==1" @click="skipArticle(col.id)">
                                    <div class="bg-white edution-sub-inner flex" v-show="num<=3">
                                        <img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='col.cover2?col.cover2:col.cover1' class="mobile-cover" v-if="isMobile">
                                        <img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='col.cover1' class="bali-logo" @click="skipArticle(col.id)" v-else>
                                        <div class="m-l-16 flex-item art-list-text">
                                            <div class="sub-title">{{col.title}}</div>
                                            <div class="m-t-10 text-gray">{{col.subhead}}</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <!-- 了解八里 -->
                <h2 class="m-t-30 title" id="bali"></h2>
                <ul class="m-t-10 row list">
                    <li v-for="(item,index) in bali" :key="index">
                        <div class="list-item bali-item p15">
                            <div class="sub-title">{{item.title}}</div>
                            <div class="m-t-10">{{item.desc}}</div>
                        </div>
                        <div class="list-detail flex" v-if="false">
                            <img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='item.icon' class="bali-logo">
                            <div class="m-l-16 flex-item art-list-text">
                                <div class="sub-title">{{item.detail}}</div>
                                <div class="m-t-10 text-gray">{{item.text}}</div>
                            </div>
                        </div>
                        <!-- 八里智库 -->
                        <div class="list-detail flex pointer" v-show="index==0 && !isMobile"  @click="skipArticle(zhiku[0].id)">
                            <img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='zhiku[0].cover1' class="bali-logo">
                            <div class="flex-item art-list-text" :class="{'m-l-16': !isMobile}">
                                <div class="sub-title">{{zhiku[0].title}}</div>
                                <div class="m-t-10 text-gray">{{zhiku[0].subhead}}</div>
                            </div>
                        </div>
                        <div class="list-zhiku flex flex-wrap pointer" v-show="index==0 && isMobile">
                            <div class="zhiku-item tac" v-for="(col,num) in zhiku" :key="num" @click="skipArticle(col.id)">
                                <img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='col.cover1' class="bali-logo">
                            </div>
                            <!-- <div class="zhiku-item tac" v-for="(col,num) in zhiku" :key="num" @click="skipArticle(col.id)">
                                <img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='col.cover1' class="bali-logo">
                            </div>
                            <div class="zhiku-item tac" v-for="(col,num) in zhiku" :key="num" @click="skipArticle(col.id)">
                                <img alt="bali-logo tac" src="/img/bali-logo.png"  v-default-img='col.cover1' class="bali-logo">
                            </div>
                            <div class="zhiku-item tac" v-for="(col,num) in zhiku" :key="num" @click="skipArticle(col.id)">
                                <img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='col.cover1' class="bali-logo">
                            </div>-->
                        </div>
                        <!-- 八里创投 -->
                        <div class="list-detail flex pointer" v-show="index==1 && !isMobile" @click="skipArticle(chuangtou[0].id)">
                            <img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='chuangtou[0].cover1' class="bali-logo" v-show="!isMobile">
                            <div class="flex-item art-list-text" :class="{'m-l-16': !isMobile}">
                                <div class="sub-title">{{chuangtou[0].title}}</div>
                                <div class="m-t-10 text-gray">{{chuangtou[0].subhead}}</div>
                            </div>
                        </div>
                        <div class="list-zhiku flex flex-wrap pointer" v-show="index==1 && isMobile">
                            <div class="zhiku-item tac" v-for="(col,num) in chuangtou" :key="num" @click="skipArticle(col.id)">
                                <img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='col.cover1' class="bali-logo">
                            </div>
                            <!--<div class="zhiku-item tac" v-for="(col,num) in chuangtou" :key="num" @click="skipArticle(col.id)">
                                <img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='col.cover1' class="bali-logo">
                            </div>
                            <div class="zhiku-item tac" v-for="(col,num) in chuangtou" :key="num" @click="skipArticle(col.id)">
                                <img alt="bali-logo tac" src="/img/bali-logo.png"  v-default-img='col.cover1' class="bali-logo">
                            </div>
                            <div class="zhiku-item tac" v-for="(col,num) in chuangtou" :key="num" @click="skipArticle(col.id)">
                                <img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='col.cover1' class="bali-logo">
                            </div>-->
                        </div>
<!--                        <ul class="list-detail flex tac" v-else>-->
<!--                            <li class="flex-item" v-for="(col,num) in item.detail" :key="num" >-->
<!--                                <img alt="icon" :src="col.icon">-->
<!--                                <div>{{col.text}}</div>-->
<!--                            </li>-->
<!--                        </ul>-->
                    </li>
                </ul>
            </div>
        </div>
        <!--  取自：了解八里云-->
    </div>
</template>
<script>
    export default {
        data(){
            return {
                active:0,
                menu:[{name:"云产品",location:"cloud",distance:0},{name:"解决方案",location:"solution",distance:0},{name:"八里云大学",location:"edution",distance:0},{name:"了解八里云",location:"bali",distance:0}],
                location:0,
                classify:[
                    {title:"云产品",sub:["SaaS 类企培 e-learning"]},
                    {title:"解决方案",sub:["行业解决方案","通用解决方案"]},
                    {title:"云教育",sub:["八里云大学","K12教育"]},
                    {title:"了解八里云",sub:["八里云科技","八里云教育","八里智库","八里创投"]}
                ],
                solution:[
                    {title:"0农业监测大数据平台",subhead:"大数据技术广泛应用在农产品监测预警领域,协助政府构建农业基准数据、开展农产品信息实时化采集技术研究、构建复杂智能模型分析系统、建立可视化的预警服务平台"},
                    {title:"1能源工业互联网平台",subhead:"为能源工业企业在智能制造透明工厂管理、机器在线管理（服务、智造、研发、能源）、产业链平台、工业AI、设备融资等方面提供数字化转型服务和实施方案。"},
                    {title:"2可视化数据大屏解决方案",subhead:"为政企部门的数据业务快速可供可视化大屏解决方案。"},
                    {title:"3心理教育数字化转型解决方案",subhead:"为心理咨询企业提供面向用户和咨询师的心理咨询、心理测试、心理教育和培训等一站式数字化智能服务解决方案。"}
                ],
                edution:[
                    {title:"解决方案",desc:"",
                        sub:[
                            {title:"商学院e-Learning云学堂",desc:"为企业管理培训行业提供3000门在线培训课程，一站式帮助企业建立培训体系、建设数字系统及课程采购，成本可控地快速实施数字化学习型组织的建设和运营。"},
                            {title:"蓝领学院e-Learning云学堂",desc:"为传统职业技能培训机构、大型组织提供覆盖面包师、汽修工、育婴师等国家指定职业技能鉴定的工种提供一站式在线培训服务，帮助地方政府快速建立扶贫就业数字化解决方案和落地系统。"},
                            {title:"IT编程e-Learning云机房",desc:"为IT学生、IT从业者提供在线学习、在线实验的一站式IT编程服务，同时通过云机房模式有效解决高校计算机教学采用传统机房教学的固定模式。"},
                            {title:"设计学院e-Learning云学堂",desc:"为创意设计类爱好者、从业者提供在线学习的，并提供推荐就业的一站式服务。"},
                        ]
                    },
                    {title:"资讯动态",desc:"",
                        sub:[
                            {title:"K12-HelloWod学习机堂",desc:"为青少年学生提供全球级视野的、符合学校管控要求、以全人教育理念为核心的平板学习机。"},
                            {title:"早幼教-HelloWod学习机",desc:"为3-6岁儿童提供一流的、以全人教育理念为核心在线学习方式的平板学习机。"},
                            // {title:"早幼教-HelloWod学习机",desc:"为IT学生、IT从业者提供在线学习、在线实验的一站式IT编程服务，同时通过云机房模式有效解决高校计算机教学采用传统机房教学的固定模式。"},
                            // {title:"设计学院e-Learning云学堂",desc:"为创意设计类爱好者、从业者提供在线学习的，并提供推荐就业的一站式服务。"},
                        ]
                    }
                ],
                bali:[
                    {title:"招聘信息",desc:"",
                        icon:"/img/bali-logo.png",detail:"产业规划与落地政策",text:"在算法与数据的驱动下，对当地的城市战略、产业定位、产业体系、产业结构、园区规划、实施方案等做出科学计划，同时辅以政策解读，招商目标推荐，为政府提供切实可行发展规划。"
                    },
                    {title:"城市合作",desc:"",
                        icon:"/img/bali-sange.png",
                        detail:"三格理心理数字平台项目",text:"在算法与数据的驱动下，对当地的城市战略、产业定位、产业体系、产业结构、园区规划、实施方案等做出科学计划，同时辅以政策解读，招商目标推荐，为政府提供切实可行发展规划。"
                    },
                ],
                wuzhen:['/img/wuzhen.png','/img/wuzhen.png','/img/wuzhen.png','/img/wuzhen.png'],
                cloudEducate:[],
                qiyedaxue:{}, //企业大学SaaS云-5
                xinlifuwu:{}, //心理服务SaaS云-15
                university:[],//八里云大学-16
                k12:[],  //K12云学校：17
                //了解八里
                jiaoyu:{},  //八里教育-11
                keji:{},    //八里科技-12
                zhiku:[],   //八里智库-13  ? {}
                chuangtou:[], //八里创投-15  {}
                maoyi:{},//八里贸易-27
                xinwen:{},// 新闻报道-28，?
                huoban:{}, // 合作伙伴-31
                isShowFlag:false
            }
        },
        mounted() {
            this.queryData()
            this.queryAtcs()
            // 计算距离顶部距离//this.menu[1].distance = document.querySelector('#solution').offsetTop  // this.menu[2].distance = document.querySelector('#edution').offsetTop
            //this.menu[3].distance = document.querySelector('#bali').offsetTop //console.log(this,this.isMobile,this.menu)
        },
        methods:{
            queryData(){ //获取所有菜单、子菜单项
                let _this = this
                this.$fetch.allType().then(res=>{
                    console.log(res.data.data)
                    if(res.data.code ==2000){
                        _this.classify = res.data.data
                        // for(let i in _this.classify){
                        //     _this.$set(_this.classify[i],"isShowSub",false)
                        // }
                    }else{
                        this.$message.error('获取失败')
                    }
                }).catch(err=>{
                    this.$message.error(err,'获取失败')
                })
            },
            async queryAtcs(){
                 function params(id){ //设置参数
                    return {currentPage: 0,pageSize: 10, typeId: id }
                 }
                 const products = await Promise.all([this.$fetch.infoSearch(params(5)),this.$fetch.infoSearch(params(14))])
                 if(products[0].data.code == 2000) {  //云产品：企业大学SaaS云-5，心理服务SaaS云-15，在线直播云平台-30
                    this.qiyedaxue = products[0].data.data.rows[0]
                    this.xinlifuwu = products[1].data.data.rows[0]
                    //console.log("云产品",this.qiyedaxue, this.xinlifuwu)
                 }
              //解决方案；6、7、8、9  修改为  数字运营商 id=3
                 const solutions = await Promise.all([this.$fetch.infoSearch(params(3)),this.$fetch.infoSearch(params(7)),this.$fetch.infoSearch(params(8)),this.$fetch.infoSearch(params(9))])
                 //console.log("解决方案",solutions)
                 if(solutions[0].data.code == 2000){  //解决方案；6、7、8、9
                    this.solution[0].title = solutions[0].data.data.rows[0].title
                    this.solution[0].subhead = solutions[0].data.data.rows[0].subhead
                    this.solution[0].id = solutions[0].data.data.rows[0].id
                    this.solution[0].cover1 = solutions[0].data.data.rows[0].cover1
                    this.solution[0].cover2 = solutions[0].data.data.rows[0].cover2

/*                    this.solution[1].title = solutions[1].data.data.rows[0].title
                    this.solution[1].subhead = solutions[1].data.data.rows[0].subhead
                    this.solution[1].id = solutions[1].data.data.rows[0].id
                     this.solution[1].cover1 = solutions[1].data.data.rows[0].cover1
                     this.solution[1].cover2 = solutions[1].data.data.rows[0].cover2
                    this.solution[2].title = solutions[2].data.data.rows[0].title
                    this.solution[2].subhead = solutions[2].data.data.rows[0].subhead
                    this.solution[2].id = solutions[2].data.data.rows[0].id
                    this.solution[2].cover1 = solutions[2].data.data.rows[0].cover1
                    this.solution[2].cover2 = solutions[2].data.data.rows[0].cover2
                    this.solution[3].title = solutions[3].data.data.rows[0].title
                    this.solution[3].subhead = solutions[3].data.data.rows[0].subhead
                    this.solution[3].id = solutions[3].data.data.rows[0].id
                    this.solution[3].cover1 = solutions[3].data.data.rows[0].cover1
                    this.solution[3].cover2 = solutions[3].data.data.rows[0].cover2*/

                     //console.log("解决方案",this.solution)
                 }
                 // K12云学校：17 修改为10
                // 八里云大学-16 修改为 4、5、6、28
                 const educations = await Promise.all([this.$fetch.infoSearch(params(4)),this.$fetch.infoSearch(params(10))])//云教育:  八里云大学-16，K12云学校：17
                 //console.log(educations)
                 if(educations[0].data.code == 2000){
                     this.university = educations[0].data.data.rows
                     this.k12 = educations[1].data.data.rows
                     //this.cloudEducate[0] = educations[0].data.data.rows
                    //this.cloudEducate[1] = educations[1].data.data.rows
                    //console.log(this.university,this.k12)
                 }
                //了解八里云 : 八里教育-11，八里科技-12，*八里智库-13，*八里创投-15，八里贸易-27，新闻报道-28，合作伙伴-31
              // 八里创投-15 修改为 城市合作  18  19
              // 八里智库-13  修改为  招聘信息 23
                const about = await Promise.all([ this.$fetch.infoSearch(params(11)),this.$fetch.infoSearch(params(12)),this.$fetch.infoSearch(params(23)),this.$fetch.infoSearch(params(18))])
                if(about[0].data.code == 2000){
                    console.log("了解八里云",about)
                    this.jiaoyu = about[0].data.data.rows[0]
                    this.keji = about[1].data.data.rows[0]
                    this.zhiku = about[2].data.data.rows  //八里智库 [0]  第一个cover1 空的
                    this.chuangtou = about[3].data.data.rows   //八里创投[0]
                   //this.about = [about[0].data.data.rows[0],about[1].data.data.rows[0]]
                    //this.cloudEducate[1] = educations[1].data.data.rows
                    console.log("智库：",this.zhiku,"创投",this.chuangtou)
                }
                const info = await Promise.all([ this.$fetch.infoSearch(params(27)),this.$fetch.infoSearch(params(28)),this.$fetch.infoSearch(params(31)) ])
                //console.log(info)
                if(info[0].data.code == 2000){
                    //console.log("了解八里云",info)
                    this.maoyi = info[0].data.data.rows[0]
                    this.xinwen = info[1].data.data.rows.length?info[1].data.data.rows[0]:{}
                    this.huoban = info[2].data.data.rows[0]
                    //this.about = [about[0].data.data.rows[0],about[1].data.data.rows[0]]
                    //this.cloudEducate[1] = educations[1].data.data.rows
                    console.log(this.maoyi,this.xinwen,this.huoban)
                }

            },
            skipList(){
                this.$router.push("/list")
            },
            menuSwitch(index){
                this.active = index
                console.log(index)
                //this.location = "#"+this.menu[index].location
                //console.log(document.querySelector('#' + this.menu[index].location).offsetTop,document.querySelector('#' + this.menu[index].location).clientTop,document.querySelector('#' + this.menu[index].location).scrollTop)
                //计算 该元素，距离顶部距离
                //document.body.scrollTop = "1000px"//this.menu[index].distance
                //window.scrollTo(0,this.menu[index].distance)
                window.scrollTo(0,this.menu[index].distance)
                console.log(document.documentElement.scrollTop )

            },
            skipArticle(id){
                this.$router.push("/article/" + id)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .cover-slide img{
        width: 120px;
        height: 120px;
    }
    .list-zhiku{
        background:#F8F8F8;
        .zhiku-item{
            padding:15px 0;
            margin-bottom: 2px;
            background: #fff;
            width: calc( (100% - 2px) /2);
            &:nth-child(odd){
                margin-right:2px
            }
        }
    }

    .solution-cover{
        width:120px;
        height: 120px;
    }
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #fff;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #fff;
    }
    .product-cover{
        max-height: 100%;
    }
    .p15{
        padding: 15px
    }
    .p-x-15{
        padding: 0 15px 15px 15px
    }
    .text-gray{ color:#999 }
    .main-center{
        width: 980px;
        margin:0 auto
    }
    .blue-title{ font-size: 16px}
    .cloud-block{
        display: block;
        .cloud-desc{
            width: 50%;
            &:not(:last-child) { border-right:2px solid #F8F8F8 }
        }
    }
    .art-list-text{
        flex-grow: 1;
        word-break: break-all;
        line-height: 22px;
    }
    .bali-logo{
        width: 90px;
        height: 90px;
    }
    .edution{
        .edution-list{
            display: flex;
            .edution-item{
                padding: 30px;
                height: 404px;
                box-sizing: border-box;
            }
            .edution-sub{
                margin-left: 16px;
                .edution-sub-item{
                    width: 50%;
                    margin-bottom: 10px;
                    .edution-sub-inner{
                        display: flex;
                        height: 196px;
                        padding: 20px;
                    }
                }
                ul>li:nth-child(odd) .edution-sub-inner{
                    margin-right: 10px;
                }
            }
        }
    }
    .list{
        display: flex;
        flex-wrap: wrap;
        li{
            width: 25%;
            flex-grow: 1;
            &:not(:last-child) { border-right:2px solid #F8F8F8 }
        }
        .list-item{
            box-sizing: border-box;
            min-height: 230px;
            background: #fff;
            line-height: 25px;
        }
        .bali-item{
            color: #fff;
            min-height: 200px;
            background: #51CCDC;
        }
        &>li:not(:last-child) .list-item {

        }
        .list-detail{
            height: 210px;
            padding: 15px;
            background: #fff;
            box-sizing: border-box;
        }
    }
    .mobile{
        .mobile-menu,.menu-view{ display: flex }
        .blue-title{color: #fff; background: #51CCDC; margin-bottom: 10px}
        .cloud {
            .cloud-block{
                display:block;
                .cloud-desc{
                    height: auto;
                    min-height:120px;
                    width: auto;
                    /*&:not(:last-child) { margin-bottom: 10px }*/
                }
            }
        }
        .header-main{
            box-shadow: 0px 0px 5px 0px rgba(203, 202, 202, 0.5);
            .menu{ display:none }
        }
        .header-left{ flex-grow: 1}
        .left-block,.wuzhen>li:nth-child(n+2){ display: none }
        .list{
            display: block;
            .list-item{ min-height: 100px}
            li{
                width:100%;
                margin-bottom: 10px;
            }
        }
        .edution{
            .edution-list{
                display: block;
                .edution-left{
                    width: 100%;
                    height: 150px;
                    padding: 15px;
                }
                .edution-sub {
                    margin-left: 0;
                    .edution-sub-item {
                        width: 100%;
                        .edution-sub-inner{
                            display: block;
                            padding: 0;
                            height: auto;
                            margin-right: 0;
                            .art-list-text{
                                margin-left: 0;
                                padding: 15px 0;
                            }
                        }
                    }
                }
            }
        }
        .product-cover{
            max-height:fit-content;
            max-width: 100%;
        }

    }
</style>

