<template>
    <div class="main" :class="{'mobile': isMobile}">
        <div class="container">
            <div class="flex cloud" id="cloud">
                <Menu></Menu>
                <div class="flex-item cloud-right article">
                    <img alt="bali-logo" src="/img/bali-logo.png"  v-default-img='article.cover2?article.cover2:article.cover1' class="bali-logo" v-if="isMobile">
                    <div class="title m-t-20"> {{article.title}}</div>
                    <div class="text-grey m-t-10">发布日期：{{article.gmtCreate}}</div>
                    <div class="m-t-10 atc-main">
<!--                        <div class="">{{article.subhead}}</div>-->
                        <div class="m-t-20" v-html="article.content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data(){
            return{
                article:{}
            }
        },
        mounted(){
            this.queryData(this.$route.params.id)
        },
        methods:{
            queryData(id){
                //console.log(id)
                let _this = this
                this.$fetch.info(id).then(res=>{
                    console.log(res.data)
                    if(res.data.code ==2000){
                        console.log(res.data.data)
                        _this.article = res.data.data
                    }else{
                        this.$message.error('获取失败');
                    }
                }).catch(err=>{
                    this.$message.error('获取失败');
                    console.log(err)
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .bali-logo{
        width: 100%;
        height: auto;
    }
    .cloud{ min-height: 250px }
    .article{
        background: #fff;
        padding: 15px;
        .atc-main{
            color: #666;
            font-size: 14px;
            line-height: 32px;
            min-height: 150px;
        }
    }
    .mobile{
        .atc-main{
            line-height: 22px;
        }
    }
</style>

